export default function orderLocationsByType(arr) {
  const institutes = arr.filter(
      ({ properties }) =>
        properties.category?.includes("Institutes") ||
        properties.category?.includes("Specialty Clinics") ||
        properties.category?.includes("Infusion & Radiation Centers") ||
        properties.category?.includes("Family Birthing Centers") ||
        properties.category?.includes("Sleep Centers") ||
        (properties.category?.includes("Emergency Services") &&
          !properties.category?.includes("Hospitals"))
    ),
    withoutInstitutes = arr.filter(
      ({ properties }) =>
        !properties.category?.includes("Institutes") &&
        !properties.category?.includes("Infusion & Radiation Centers") &&
        !properties.category?.includes("Specialty Clinics") &&
        !properties.category?.includes("Family Birthing Centers") &&
        !properties.category?.includes("Sleep Centers") &&
        !(
          properties.category?.includes("Emergency Services") &&
          !properties.category?.includes("Hospitals")
        )
    );
  const medGroup = withoutInstitutes.filter(
      ({ properties }) =>
        properties.category?.includes("Baylor St. Luke's Medical Group") ||
        properties.category?.includes(
          "CHI St. Joseph and Texas A&M Health Network"
        )
    ),
    withoutMedGroupAndInstitutes = withoutInstitutes.filter(
      ({ properties }) =>
        !properties.category?.includes("Baylor St. Luke's Medical Group") &&
        !properties.category?.includes(
          "CHI St. Joseph and Texas A&M Health Network"
        )
    );
  const hospitals = withoutMedGroupAndInstitutes.filter(({ properties }) =>
      properties.category?.includes("Hospitals")
    ),
    withoutHospitalsMedGroupAndInstitues = withoutMedGroupAndInstitutes.filter(
      ({ properties }) => !properties.category?.includes("Hospitals")
    );
  const otherLocations = withoutHospitalsMedGroupAndInstitues;
  return [...institutes, ...medGroup, ...hospitals, ...otherLocations];
}
