export default function closestLocation(targetLocation, locationData) {
  const vectorDistance = (dx, dy) => Math.hypot(dx, dy);

  const locationDistance = (location1, location2) => {
    let dx = location1.geometry.coordinates[0] - location2.geometry.coordinates[0],
        dy = location1.geometry.coordinates[1] - location2.geometry.coordinates[1];

    return vectorDistance(dx, dy);
  }

  let result = {}
  for (let i=0; i<locationData.length; i++) {
    const mileDistance = locationDistance(locationData[i],targetLocation) * 69.2;
    result[locationData[i].properties.storeid] = Number.parseFloat(mileDistance).toFixed(2)
  }
  return result
}
