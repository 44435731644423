import orderLocationsByType from "./orderLocationsByType";
import memorialLast from "./memorialLast";
import encodeSpecialChar from "./encodeSpecialChar"

function replaceServices(item) {
  return item
    .replace(/^Cardiovascular Care$/, "Cardiology")
    .replace(/^Robotic Cardiac Surgery$/, "Robotic Surgery")
    .replace(/^Infectious Disease$/, "Infectious Diseases")
    .replace(/^Orthopedics$/, "Orthopedics & Sports Medicine")
}

export function encodeURLsafeValue(item) {
  return item
    .replace(/ +/g, '-')
    .replace("Luke's", "lukes")
    .replace("Women's", "womens")
    .replace("Ear,", "ear")
    .replace("ear,", "ear")
    .replace("St.", "st")
    .replace(" & ", "and")
    .replace("Ob/Gyn", "Ob-Gyn")
}

export function decodeURLsafeValue(item) {
  return item
    .replace( /-/g, " ")
    .replace( "lukes", "Luke's")
    .replace( "womens", "Women's")
    .replace( "ear", "Ear,")
    .replace( " st ", " St. ")
    .replace( "and", " & ")
    .replace("Ob-Gyn", "Ob/Gyn")
    .replace("chi", "")
}

export default function drupalTransformCHI( {features} ) {
  const defaultSort = orderLocationsByType(memorialLast(features))

  return defaultSort.map(({ properties }) => {
    const { 
      name, icon, phone, category, titleLink, description, directionURL
    } = properties

    const {street, suite, state, city, zip} = properties
    const address = `${street} ${suite ? suite + ", ": ", "}${city}, ${state} ${zip}`

    const extractCoordsFromString = properties.coordinates.split(",")
    const coordinates = [
      parseFloat(extractCoordsFromString[1]),
      parseFloat(extractCoordsFromString[0])
    ]

    return {
      geometry: {
        type: "Point",
        coordinates
      },
      type: "Feature",
      properties: {
        name,
        phone,
        address: encodeSpecialChar(address),
        titleLink: titleLink.replace("https://about.", "https://www.").replace("https://about-stjoseph.", "https://stjoseph."),
        description,
        directionURL,
        iconURL: icon,
        geoResult: true,
        // services: properties.Services,
        storeid: properties.Nid,
        services: properties.Services?.split("|").map(serv => encodeURLsafeValue(replaceServices(encodeSpecialChar(serv))).toLowerCase()),
        // category,
        categories: category?.split("|").map(cate => encodeURLsafeValue(encodeSpecialChar(cate)).toLowerCase()),
        // category,
        // category: category.match(new RegExp(locationTypes.join('|'), 'gi')) || [],
        locationHeader: properties.locationHeader,
        locationHeaderRetinaSrc: properties.locationHeaderRetinaSrc,
      }
    }
  })
} 
