import React from "react";

function ResultItem(feature) {
  if (!feature) return
  const {
    name,
    phone,
    address,
    storeid,
    iconURL,
    titleLink,
    directionURL,
    locationHeader,
    locationHeaderRetinaSrc,
  } = feature.properties;

  const addressArr = address.split(",")

  return (
    <React.Fragment key={"result" + storeid}>
      <div className="result-item-parent-container">
        <div className="result-item-container">
          <div className="result-item-left-column">
            <div className="result-item-image-wrapper">
              <img 
                loading="lazy"
                style={{ maxWidth: "100%" }}
                src={locationHeader && locationHeader.src ? locationHeader.src : iconURL}
                srcSet={locationHeaderRetinaSrc ? `${locationHeaderRetinaSrc} 2x` : ''}
                alt={locationHeader && locationHeader.alt !== "" ? locationHeader.alt : `A photo of ${name}`} 
                title={locationHeader && locationHeader.title !== "" ? locationHeader.title : `A photo of ${name}`}
                role="presentation" 
              />
            </div>
          </div>
          <div className="result-item-right-column">
            <a href={titleLink} className="result-item-title">
              <h3 className="primary no-margin">
              {name}
              </h3>
            </a>
            <a className="paragraph-normal" href={"tel:" + phone}>{phone}</a>
            <address className="result-item-address" data-country="US">
              <span className="paragraph-normal">{addressArr[0]}</span>
              <span className="paragraph-normal">{addressArr[1] + ", " + addressArr[2]}</span>
            </address>
          </div>
        </div>
        <div className="result-item-footer">
          <a 
              href={directionURL} 
              target="_blank" 
              rel="noreferrer noopener"
              className="paragraph-normal teal"
              aria-label={`More information about the ${name} location`}
            >View Location</a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function ResultsList(props) {
  const { features, allFeatures, associatedLocations, serviceLine, isUsedAsWidget } = props;
  const results = features.map((feature) => ResultItem(feature));

  return (
      <div id="map-result-list">
        {(serviceLine || !isUsedAsWidget) && results}
        {associatedLocations?.associatedHospitals?.length > 0 && <>
          <h3>Associated Hospitals</h3>
          {associatedLocations?.associatedHospitals?.map(locID => ResultItem(allFeatures.find(({ properties}) => properties.storeid === locID.toString())))}
        </>}
        {associatedLocations?.associatedInstitutes?.length > 0 && <>
          <h3>Associated Institutes</h3>
          {associatedLocations?.associatedInstitutes?.map(locID => ResultItem(allFeatures.find(({ properties}) => properties.storeid === locID.toString())))}
        </>}
        {associatedLocations?.medicalGroupLocations?.length > 0 && <>
          <h3>Medical Group Locations</h3>
          {associatedLocations?.medicalGroupLocations?.map(locID => ResultItem(allFeatures.find(({ properties}) => properties.storeid === locID.toString())))}
        </>}
        {associatedLocations?.other?.length > 0 && <>
          <h3>Other Locations</h3>
          {associatedLocations?.other?.map(locID => ResultItem(allFeatures.find(({ properties}) => properties.storeid === locID.toString())))}
        </>}
      </div>
  );
}
