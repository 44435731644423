import React from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api';

function LocationMarker(location, openCard, setOpenCard) {
  const { name, description, storeid, address, phone, titlelink } = location.properties
  const { coordinates } = location.geometry
  const lng = coordinates[0]
  const lat = coordinates[1] 

  return (
    <div key={`fragment-${storeid}`}>
      <Marker
        key={`marker-${storeid}`}
        position={{ lat, lng }}
        // icon={"../../../images/pin-2x.png"}
        clickable={true}
        title={name}
        onClick={() => setOpenCard(storeid)}
      />
      {openCard === storeid 
        ?  (<InfoWindow 
              onCloseClick={() => setOpenCard(undefined)} 
              position={{ lat, lng }}
              options={{pixelOffset: new window.google.maps.Size(-1,-34)}}
            >
              <div className={"infoCard"}>
                <h2 className={"whiteText"}><a className={"whiteText"} href={titlelink} rel="noopener noreferrer" target="_blank">{name}</a></h2>
                {description && <p className={"whiteText"}>{description}</p>}
                {/* {storeIdsDistance[storeid] && <p className={"whiteText"}><strong>{storeIdsDistance[storeid]} mi away</strong></p>} */}
                <p className={"whiteText"}>{address}</p>
                <p className={"whiteText"}><b>Phone:</b> <a className={"whiteText"} href={`tel:{phone}`}>{phone}</a></p>
              </div>
            </InfoWindow>)
        :  null
      }
    </div>
  )
}

const LocationMarkers = ({ locations, openCard, setOpenCard}) => 
  locations
    .map(location => LocationMarker(location, openCard, setOpenCard))

export default LocationMarkers