import React, { useEffect } from 'react'
import { GoogleMap } from '@react-google-maps/api';
import LocationMarkers from './LocationMarkers'
import { useWindowSize } from '../hooks/useWindowSize';

function MapContainer({ features, setMapHasMounted, height, mapCenter, mapZoom, isUsedAsWidget }) {
  const [center, setCenter] = React.useState(mapCenter)
  const [zoom, setZoom] = React.useState(mapZoom)
  const [storeIdsDistance/*, setStoreIdsDistance*/] = React.useState([])
  const [openCard, setOpenCard] = React.useState(undefined)
  const { width } = useWindowSize()
  const documentHeight = window.document.body.offsetHeight

  useEffect(() => {
    setMapHasMounted(true)
    return () => setMapHasMounted(false)
  }, [setMapHasMounted])

  useEffect(() => {
    setCenter(mapCenter)
    setZoom(mapZoom)
  }, [mapCenter, mapZoom])

  useEffect(() => {
    const coords = features.map(({ geometry }) => ({latitude: geometry.coordinates[1], longitude: geometry.coordinates[0]}))
    const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length
    
    const centerLat = arrAvg(coords.map(c=>c.latitude));
    const centerLon = arrAvg(coords.map(c=>c.longitude));

    if (isNaN(centerLat)|| isNaN(centerLon))
        setCenter(mapCenter)
    else setCenter({lat: centerLat, lng:centerLon})

  }, [features, mapCenter])

  useEffect(() => {
    if(!isUsedAsWidget) {
      const paginatorOffset = 58
      const containerHeight = window.document.querySelector("#parent-container > div.right-container").offsetHeight
      const handleScroll = () => {
        if (window.document.scrollingElement.scrollTop > (containerHeight - (height + paginatorOffset)) ) {
          document.querySelector("#parent-container > div.right-container > div").classList.add("at-bottom")
        } else {
          document.querySelector("#parent-container > div.right-container > div").classList.remove("at-bottom")
        }
      }
      document.addEventListener("scroll", handleScroll, { passive: true })
      
      return () => document.removeEventListener("scroll", handleScroll)
    }
  }, [isUsedAsWidget, width, documentHeight, height]) 

  const containerStyle = {
    width: '100%',
    height: `${height}px`
  };

  return (
    <div className="map-container">
      <GoogleMap
        // options={{}}
        center={center}
        zoom={zoom}
        mapContainerStyle={containerStyle}
      >
        <LocationMarkers 
          openCard={openCard} 
          locations={features} 
          setOpenCard={setOpenCard} 
          storeIdsDistance={storeIdsDistance}
        />
      </GoogleMap>
    </div>
  )
}

export default MapContainer
