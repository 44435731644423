export default function memorialLast(locationsArray) {
  if (locationsArray) {
    const isMemorial = ({ properties }) =>
      properties.category?.toLowerCase().includes("memorial") ||
      properties.name?.toLowerCase().includes("livingston") ||
      properties.name?.toLowerCase().includes("lufkin") ||
      properties.name?.toLowerCase().includes("san augustine") ||
      properties.name?.toLowerCase().includes("memorial");

    return [
      ...locationsArray.filter((location) => !isMemorial(location)),
      ...locationsArray.filter(isMemorial),
    ];
  }
  return null;
}
