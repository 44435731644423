import React, { useEffect } from "react";
import { usePosition } from "../hooks/usePosition";

export default function Geolocation(props) {
  const { setUserPosition, userLocationOn, setUserLocatioonOn } = props;
  const { latitude, longitude } = usePosition(false, userLocationOn);

  const handleClick = () => {
    if (userLocationOn) {
      setUserPosition(null)
    }
    setUserLocatioonOn(!userLocationOn)
  };

  useEffect(() => {
    if (userLocationOn && !!latitude && !!longitude) {
      setUserPosition({latitude, longitude});
    }
  }, [userLocationOn, latitude, longitude, setUserPosition]);

  return (
    <>
      <button style={{ padding: 0, height: "40px" }} onClick={handleClick} aria-label="Use my geolocation in order to sort results by proximity">
        {/* We should consider adding a loader here while window.navigator is working... */}
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="20" height="20" viewBox="0 0 14 20"
        >
          <g
            className={userLocationOn ? "active" : "disabled"}
            fill={userLocationOn ? "grey" : "lightgrey"}
            stroke="none"
          >
            <path d="M7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5C5.62 9.5 4.5 8.38 4.5 7S5.62 4.5 7 4.5 9.5 5.62 9.5 7 8.38 9.5 7 9.5z"></path>
          </g>
        </svg>
      </button>
    </>
  );
}
