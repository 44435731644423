import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

export const init = (config) => ReactDOM.render(
  <React.StrictMode>
    <App config={config} />
  </React.StrictMode>,
  document.getElementById(config.selectorId)
)

process.env.NODE_ENV === "development" && 
  init({
    selectorId: "find-a-location-root", 
    clientId: "slh",
    // serviceLine: "womens health &amp; maternity",
//     associatedLocations: {
//     //   associatedHospitals: [],
//     //   associatedInstitutes: [],
//     //   medicalGroupLocations: [],
//     // other: [23822],
//     }
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(process.env.NODE_ENV === "development" 
//   ? console.log 
//   : null
// );
