import { useState } from "react";

export default function Accordion(props) {
  const { children, title } = props;
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div aria-label="Expandable accordion to filter results further by specialty name" aria-expanded={isActive} tabIndex="0" role="button" id="services-specialties-title-container" className={isActive ? "accordion-title" : "accordion-title title-close"} onKeyPress={(event) => { 
        if (event.key === 'Enter' || event.key === " " || event.key === "Spacebar") {
            event.preventDefault()
            setIsActive(!isActive)
          }                 
        }} onClick={() => setIsActive(!isActive)}>
        <h3>{title}</h3>
        <div id="chevron" className={isActive ? "active" : ""} />
      </div>
      <div aria-hidden={!isActive} className={isActive ? "accordion-items" : "accordion-items acc-close"}>
        {children.map((child, index) => (
          <div key={index + "accordion-item"} className="accordion-item">
            {isActive && <>{child}</>}
          </div>
        ))}
      </div>
    </>
  );
}
