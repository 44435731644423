/* eslint-disable no-useless-escape */
import React from 'react';
import Accordion from './Accordion';
import { decodeURLsafeValue } from '../utils/drupalTransformCHI';

export default function FilterPane(props) {
  const { 
    allServiceFilters, 
    allLocationFilters,
    serviceFilters,
    setServiceFilters,
    locationFilters,
    setLocationFilters,
    enabledServiceLineFilters
  } = props

  const styles = { 
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start"
  }

  return (
    <>
      <div className="filter-pane" key="location-type">
        <h2>Location Type</h2>
        <div style={styles}>
          {allLocationFilters.sort().map(filter => {
              const sanitizedFilter = filter.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s+]/g,"-").toLowerCase();
              return <div className={"accordion-item"} style={{ width: "50%"}} key={"checkbox-for-" + sanitizedFilter }>
                <div className="checkbox-container">
                  <input 
                    type="checkbox"  
                    id={"checkbox-for-" + sanitizedFilter } 
                    aria-hidden="true"
                    aria-labelledby={"checkbox-label-" + sanitizedFilter } 
                    value={filter} 
                    checked={locationFilters.includes(filter)} 
                  />
                  <label 
                    aria-label={`Filter by ${decodeURLsafeValue(filter)}`}
                    htmlFor={"checkbox-for-" + sanitizedFilter } 
                    role="checkbox" 
                    aria-checked={locationFilters.includes(filter)} 
                    tabIndex="0"
                    onClick={({ target }) => {
                      locationFilters.includes(filter)
                        ? setLocationFilters(locationFilters.filter(f => f !== filter))
                        : setLocationFilters([ target.previousElementSibling.value, ...locationFilters])
                      }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' || event.key === " " || event.key === "Spacebar") {
                        event.preventDefault()
                        locationFilters.includes(filter)
                          ? setLocationFilters(locationFilters.filter(f => f !== filter))
                          : setLocationFilters([ event.target.previousElementSibling.value, ...locationFilters])
                      }
                    }}
                    ></label>
                </div>
                <span
                  id={"checkbox-label-" + sanitizedFilter } 
                  className="checkbox-label"
                  role="presentation"
                  onClick={({ target }) => {
                  locationFilters.includes(filter)
                    ? setLocationFilters(locationFilters.filter(f => f !== filter))
                    : setLocationFilters([ target.previousElementSibling.firstChild.value, ...locationFilters])
                  }}>
                  {decodeURLsafeValue(filter)}
                </span>
              </div>
            })}
        </div>
      </div>
      <div className="filter-pane" key="services-specialties">
        <Accordion title="Services &amp; Specialties">
          {allServiceFilters.sort().map(filter => {
            const sanitizedFilter = filter.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s+]/g,"-").toLowerCase();
            return (<React.Fragment key={"checkbox-for-" + sanitizedFilter }>
            <div className="checkbox-container">
              <input 
                type="checkbox"  
                id={"checkbox-for-" + sanitizedFilter } 
                value={filter} 
                aria-hidden="true"
                checked={serviceFilters.includes(filter)} 
                disabled={enabledServiceLineFilters?.length ? !enabledServiceLineFilters.includes(filter) : false}
                aria-disabled={enabledServiceLineFilters?.length ? !enabledServiceLineFilters.includes(filter) : false}
              />
              <label 
                aria-label={`Filter by ${decodeURLsafeValue(filter)}`}
                htmlFor={"checkbox-for-" + sanitizedFilter }
                role="checkbox" 
                tabIndex={enabledServiceLineFilters?.length && !enabledServiceLineFilters.includes(filter) ? "-1" : "0"}
                aria-checked={serviceFilters.includes(filter)} 
                disabled={enabledServiceLineFilters?.length ? !enabledServiceLineFilters.includes(filter) : false}
                aria-disabled={enabledServiceLineFilters?.length ? !enabledServiceLineFilters.includes(filter) : false}
                onClick={({ target }) => {
                  serviceFilters.includes(filter)
                    ? setServiceFilters(serviceFilters.filter(f => f !== filter))
                    : setServiceFilters([ target.previousElementSibling.value, ...serviceFilters])
                }}
                onKeyPress={(event) => {
                      if (event.key === 'Enter' || event.key === " " || event.key === "Spacebar") {
                        event.preventDefault()
                        serviceFilters.includes(filter)
                        ? setServiceFilters(serviceFilters.filter(f => f !== filter))
                        : setServiceFilters([ event.target.previousElementSibling.value, ...serviceFilters])
                      }
                    }}
              ></label>
              </div>
              <span
                id={"checkbox-label-" + sanitizedFilter } 
                className="checkbox-label"
                role="presentation"
                onClick={({ target }) => {
                  serviceFilters.includes(filter)
                    ? setServiceFilters(serviceFilters.filter(f => f !== filter))
                    : setServiceFilters([ target.previousElementSibling.firstChild.value, ...serviceFilters])
                }}
                >{decodeURLsafeValue(filter)}</span>
              </React.Fragment>)
          })}  
        </Accordion>
      </div>
    </>
  );
}