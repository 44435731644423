import React, { useState, useEffect } from "react";
import MapContainer from "./components/MapContainer";
import FilterPane from "./components/FilterPane";
import drupalTransformCHI, { encodeURLsafeValue, decodeURLsafeValue } from "./utils/drupalTransformCHI";
import encodeSpecialChar from "./utils/encodeSpecialChar"
import Paginator from "./components/Paginator";
import ResultsList from "./components/ResultsList";
import Geolocation from "./components/Geolocation";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import closestLocation from "./utils/closestLocation";
// import staticLocations from "./data/locations.json"
import "./App.css";
import { useWindowSize } from "./hooks/useWindowSize";
const loadScriptLibs = ["places"];

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded = window.google && window.google.maps // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}

function App({ config }) {
  const { associatedLocations } = config;
  const serviceLine = config?.serviceLine ? encodeSpecialChar(config?.serviceLine) : undefined;
  // const { latitude, longitude } = usePosition()
  const windowSize = useWindowSize()
  const [features, setFeatures] = useState([]);
  const [results, setResults] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [allServiceFilters, setAllServiceFilters] = useState([]);
  const [allLocationFilters, setAllLocationFilters] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [resultsView, setResultsView] = useState([]);
  const [mapHasMounted, setMapHasMounted] = useState(false);
  const [enabledServiceLineFilters, setEnabledServiceLineFilters] = useState();
  const [serviceFilters, setServiceFilters] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  const [userPosition, setUserPosition] = useState(null);
  const [userLocationOn, setUserLocatioonOn] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 29.785, lng: -95.4068 });
  const [mapZoom, setMapZoom] = useState(8);
  const [distancesFromUser, setDistancesFromUser] = useState(null);
  const [searchBox, setSearchBox] = useState(undefined);
  const locationResults = !!results.length > 0 ? results : features;
  const isUsedAsWidget =
    Boolean(serviceLine) ||
    associatedLocations?.associatedHospitals ||
    associatedLocations?.associatedInstitutes ||
    associatedLocations?.medicalGroupLocations ||
    associatedLocations?.other;

  // Fetch and initialize the data, set default values.
  useEffect(() => {
    let dataURL 

    switch (config.clientId) {
      case "slh":
        dataURL = "https://find-a-location.decodedigital.co/fal-location-json"
        break;
      case "sjh":
        dataURL = "https://find-a-location.decodedigital.co/fal-location-json-sjh"
        break;
      case "development":
        dataURL = "https://find-a-location.decodedigital.co/fal-location-json"
        break;
      default:
        dataURL = config.clientId
        break;
    }

    fetch(dataURL)
        .then(res => res.json())
        .then(data => {
          // console.log(data.features.map(({ properties }) => properties.Services))
          const features = drupalTransformCHI(data);
          // console.log(features.map(({ properties }) => properties.services))
          const locationTypes = Array.from(
            new Set(features.flatMap(({ properties }) => properties.categories))
          ).filter((item) => !!item);
          const services = Array.from(
            new Set(features.flatMap(({ properties }) => properties.services))
            ).filter((item) => !!item);
          setAllLocationFilters(locationTypes);
          setAllServiceFilters(services);
          setResultsView(features.slice(0, 9));
          setAllFeatures(features);
          if(config?.serviceLine) {
            setFeatures(features.filter(feat => feat.properties.services?.includes(encodeURLsafeValue(encodeSpecialChar(config?.serviceLine).toLowerCase()))))
          } else {
            setFeatures(features);
          }
        })
  }, [config.serviceLine, config.clientId]);

  useEffect(() => {
    if (
      userPosition?.latitude &&
      userPosition?.longitude &&
      !Boolean(results.length)
    ) {
      const storeIdandDistanceFromUser = closestLocation(
        {
          geometry: {
            coordinates: [userPosition.longitude, userPosition.latitude],
          },
        },
        allFeatures
      );
      const sortedByDistance = allFeatures
        .map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            distancesFromUser: storeIdandDistanceFromUser[f.properties.storeid],
          },
        }))
        .sort(
          (a, b) =>
            a.properties.distancesFromUser - b.properties.distancesFromUser
        );
      setResults(sortedByDistance);
    }
  }, [userPosition, allFeatures, setResults, results.length]);

  useEffect(() => {
    if (mapHasMounted) {
      if (serviceLine) {
        setServiceFilters([encodeURLsafeValue(serviceLine)]);
      } else {
        const searchQuery = new URLSearchParams(window.location.search.toLowerCase());
        if (searchQuery.has("type")) {
          const replaceIncomingType = {
            "bslmg": "baylor-st-lukes-medical-group",
            "emergency": ["hospitals", "emergency-services"],
            "emergency-services": "emergency-services",
            "hospital": "hospitals",
            "institute": "institutes-and-speciality-clinics",
            "diagnostic-imaging": "diagnostic-imaging-and-labs",
            "family-birthing-center": "family-birthing-centers",
            "infusion-centers": "infusion-and-radiation-centers",
            "memorial": "memorial-(east-texas)",
            "tamu-partnership": "chi-st-joseph-and-texas-a&m-health-network"
          }
          setLocationFilters(searchQuery.getAll("type").flatMap(t => replaceIncomingType[t] ? replaceIncomingType[t] : encodeURLsafeValue(t)));
        }
        if (searchQuery.has("serv")) {
          setServiceFilters(searchQuery.getAll("serv").map(s => encodeURLsafeValue(s)));
        }
      }
    }
  }, [mapHasMounted, serviceLine]);

  useEffect(() => {
    const locationResult = features.filter((feature) =>
      feature.properties.categories?.some((category) =>
        locationFilters.includes(category)
      )
    );
    const serviceResult = features.filter((feature) =>
      feature.properties.services?.some((service) =>
        serviceFilters.includes(service)
      )
    );

    let result = []

    if(locationResult.length && !serviceResult.length) {
      result = [...locationResult];
    } else if (!locationResult.length && serviceResult.length) {
      result = [...serviceResult];
    } else {
      result = locationResult.filter((feature) => 
        feature.properties.services?.some((service) =>
          serviceFilters.includes(service)
        )
      )
    }

    if (distancesFromUser) {
      const sortedByDistance = result
        .map((r) => ({
          ...r,
          properties: {
            ...r.properties,
            distancesFromUser: distancesFromUser[r.properties.storeid],
          },
        }))
        .sort(
          (a, b) =>
            a.properties.distancesFromUser - b.properties.distancesFromUser
        );
      if (result.length > 0) setResults(sortedByDistance);
    } else {
      setResults(result);
    }
    setEnabledServiceLineFilters(
      Array.from(
        new Set(locationResult.flatMap((res) => res.properties.services))
      )
    );
  }, [
    features,
    locationFilters,
    serviceFilters,
    setResults,
    distancesFromUser,
  ]);

  // When the results, features, or current page changes update the view.
  useEffect(() => {
    const offset = pageOffset * 10;
    console.log(offset)
    const section =
      results.length > 0
        ? results.slice(offset, offset + 10)
        : features.slice(offset, offset + 10);
        console.log(results.length, features.length)
    setResultsView(section);
    setPageCount(
      results.length > 0 ? results.length / 10 : features.length / 10
    );
  }, [results, features, pageOffset]);

  // When there is a change in the number of pages reset the active page.
  useEffect(() => setPageOffset(0), [pageCount]);

  // When the user toggles "use my position" on or off this effect fires and toggles zoom and center of map.
  useEffect(() => {
    if (userPosition?.longitude && userPosition?.latitude) {
      // These are the current states for user position on.
      const storeIdandDistanceFromUser = closestLocation(
        {
          geometry: {
            coordinates: [userPosition.longitude, userPosition.latitude],
          },
        },
        allFeatures
      );
      setDistancesFromUser(storeIdandDistanceFromUser);
      setMapCenter({ lat: userPosition.latitude, lng: userPosition.longitude });
      setMapZoom(12);
    } else {
      // These should be the current state for NOT the user position
      setDistancesFromUser(null);
      setMapCenter({ lat: 29.785, lng: -95.4068 });
      setMapZoom(8);
    }
  }, [userLocationOn, userPosition, allFeatures, setDistancesFromUser]);

  const onLoad = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    const loc = searchBox.getPlaces()[0].geometry.location;
    const longitude = loc.lng();
    const latitude = loc.lat();

    setUserPosition({ longitude, latitude });
  };

  return (
    <div
      id="parent-container"
      className={`${isUsedAsWidget ? "widget": "traditional"}-presentation`}
    >
      <LoadScriptOnlyIfNeeded
        googleMapsApiKey="AIzaSyAiiCqUHsoRxfO5rJz3I1yCTk_d6dwVPMY"
        libraries={loadScriptLibs}
      >
        <div className="left-container">
          {!isUsedAsWidget && (
            <>
              <h1>Find a Location</h1>
              <div className="searchWrapper">
                <div className="searchInnerWrapper">
                  <span className="location-search-label">
                    Enter City or ZIP
                  </span>
                  <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <div className="input-container" style={{ display: "flex" }}>
                        <svg viewBox="0 0 18 18" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                          <path fill="#007785" d="M17.7505 16.7591L16.7556 17.754C16.4286 18.0845 15.8942 18.0845 15.5638 17.754L12.0587 14.2489C11.9005 14.0907 11.8126 13.8763 11.8126 13.6512C11.8126 13.4184 11.5418 13.2845 11.3478 13.4132C10.1914 14.1797 8.80489 14.6251 7.31254 14.6251C3.27305 14.6251 0 11.352 0 7.31254C0 3.27305 3.27305 0 7.31254 0C11.352 0 14.6251 3.27305 14.6251 7.31254C14.6251 8.80489 14.1797 10.1914 13.4132 11.3478C13.2845 11.5418 13.4184 11.8126 13.6512 11.8126C13.8763 11.8126 14.0907 11.9005 14.2489 12.0587L17.754 15.5638C18.081 15.8942 18.081 16.4286 17.7505 16.7591ZM2.81252 7.31253C2.81252 9.8016 4.82697 11.8126 7.31254 11.8126C9.80161 11.8126 11.8126 9.7981 11.8126 7.31253C11.8126 4.82346 9.79811 2.8125 7.31254 2.8125C4.82347 2.8125 2.81252 4.82696 2.81252 7.31253Z"></path>
                        </svg>
                      <input
                        type="text"
                        placeholder=""
                        className="location-search-input"
                        aria-label="Input an address in order to sort results by proximity, or continue tabbing to share geolocation"
                      />
                      <Geolocation
                        setUserPosition={setUserPosition}
                        userLocationOn={userLocationOn}
                        setUserLocatioonOn={setUserLocatioonOn}
                      />
                    </div>
                  </StandaloneSearchBox>
                </div>
              </div>
            </>
          )}
          {features.length > 0 ? (
            <>
              {!isUsedAsWidget && (
                <FilterPane
                  allLocationFilters={allLocationFilters}
                  allServiceFilters={allServiceFilters}
                  serviceFilters={serviceFilters}
                  setServiceFilters={setServiceFilters}
                  locationFilters={locationFilters}
                  setLocationFilters={setLocationFilters}
                  enabledServiceLineFilters={enabledServiceLineFilters}
                />
              )}
              {(serviceLine || !isUsedAsWidget) &&  <h2 
                className={`${isUsedAsWidget ? "widget-header" : "traditional-header"}`}>
                  {`${isUsedAsWidget && serviceLine ? decodeURLsafeValue(serviceLine).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}).replace("And", "and") : ""} Locations Near Me`}
              </h2>}
              <ResultsList
                isUsedAsWidget={isUsedAsWidget}
                serviceLine={serviceLine}
                features={resultsView}
                allFeatures={allFeatures}
                associatedLocations={associatedLocations}
              />
              {((serviceLine || !isUsedAsWidget)) &&<Paginator
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={windowSize.width < 768 ? 1 : 3}
                onPageChange={({ selected }) => setPageOffset(selected)}
                containerClassName={"results-list-pagination"}
                activeClassName={"results-list-active-page"}
              />}
            </>
          ) : null}
        </div>
        {(serviceLine || !isUsedAsWidget) && <div className="right-container">
        {features.length > 0 ? ( <MapContainer
            isUsedAsWidget={isUsedAsWidget}
            setMapHasMounted={setMapHasMounted}
            features={locationResults}
            height={!isUsedAsWidget ? 450 : 300}
            mapCenter={mapCenter}
            mapZoom={mapZoom}
          /> ) : null}
        </div>}
      </LoadScriptOnlyIfNeeded>
    </div>
  );
}

export default App;
